import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'
import './utils/i18n';

// Pages import Here 
import HomeDefault from "./pages/HomeDefault";
import AboutUs from "./pages/AboutUs";
import Flipbook from "./pages/Flipbook";


// Elements import Here 

import Service from "./elements/service/Service";
import CalltoAction from "./elements/calltoaction/CalltoAction";
import Gallery from "./elements/gallery/Gallery";
import Brand from "./elements/brand/Brand";

import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';
// Import Css Custom 
import './assets/css/custom.css';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeDefault}/>
                    <Route path={`${process.env.PUBLIC_URL + "/flipbook"}`} exact component={Flipbook}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service"}`} exact component={Service}/>
                    <Route path={`${process.env.PUBLIC_URL + "/brand"}`} exact component={Brand}/>
                    <Route path={`${process.env.PUBLIC_URL + "/call-to-action"}`} exact component={CalltoAction}/>
                    <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} exact component={Gallery}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`} exact component={BlogDetails}/>
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>

                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
