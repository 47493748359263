import React from 'react';
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterTwo from "../../common/footer/FooterTwo";
import Copyright from '../../common/footer/Copyright';

import SectionTitle from "../sectionTitle/SectionTitle";
import TabOne from "../../elements/tab/TabOne";
import {useTranslation} from "react-i18next";

const Service = () => {
    const {t} = useTranslation();
    return (
        <>

                <div className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 slider-area-service height-850 bg_image">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="inner pt--80 text-left">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">{t("services")}</span></h3>
                                    </div>
                                    <h1 className="title display-one">{t("services-subtitle")}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = {t("services")}
                                        title = {t("services-description")}
                                    />
                                </div>
                            </div>
                            <TabOne />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                <FooterTwo />
                <Copyright />
                </div>
            
        </>
    )
}

export default Service;
