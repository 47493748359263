import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import {useTranslation} from "react-i18next";

const ServiceTwo = ({textAlign, cardStyle}) => {
    const {t} = useTranslation();
    
const ServiceList = [
    {
        id: 0,
        title: t("Diseño-Corporativo"),
        description:
          "Creamos y desarrollamos un estilo de marca, diseñamos el branding  y todos los elementos que representan su empresa para una estrategía de comunicación interna y externa para dar el mayor alcance y obtención de las metas financieras.",
        image: "/images/service/serviice-02.jpg",
      },
      {
        id: 1,
        title: t("Marketing-Online"),
        description:
          "Nos especializamos en la gestión y venta por medio de redes sociales, campañas Ads, creación de páginas web y estrategia de mercadeo digitales. ",
        image: "/images/service/serviice-03.jpg",
      },
      {
        id: 2,
        title: t("Racebook-Online"),
        description:
          "Software de apuestas hípicas, reúne cientos de pistas de los mejores circuitos de carreras del mundo, todas las pistas de USA, Canadá, Reino Unido, Australia, Japón, Hong Kong y los más importantes clásicos de los Emiratos Árabes, Dubái y todo el calendario hípico de gala internacional, cobertura más completa del hipismo latinoamericano, con carreras desde México, Panamá, Ecuador, Rep. Dominicana, Chile, Uruguay y Argentina.",
        image: "/images/service/serviice-04.jpg",
      },
      {
        id: 3,
        title: t("Asesoria-Legal"),
        description: "Asesoria Legal para nuestros clientes a fin de que puedan obtener todos los requisitos legales para subir sus páginas web y publicar sus promociones y eventos deportivos.",
        image: "/images/service/serviice-01.jpg",
      },
]
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                  <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"  dangerouslySetInnerHTML={{__html: val.title}}></h4>
                                    <Link className="btn-default btn-small btn-border" to={{ pathname: '/service', query: { id: val.id } }}>{t("read-more")}</Link>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;