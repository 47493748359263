import React from 'react';
import SectionTitle from "../sectionTitle/SectionTitle";
import CounterUpFour from "./CounterUpFour";
import {useTranslation} from "react-i18next";

const CounterUp = () => {
    const {t} = useTranslation();
    return (
        <>

      
                <div className="main-content">

                    {/* Start Elements Area  */}
                    <div className="rwt-counterup-area rn-section-gap" style={{background: `url(${process.env.PUBLIC_URL}/images/section-bg-counter.png)`, backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = {t("about-us")}
                                        title = {t("banner-title-1")}
                                        description = ""
                                    />
                                </div>
                            </div>
                            <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    
                </div>
         
        </>
    )
}

export default CounterUp;