import React from 'react';
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import {useTranslation} from "react-i18next";

const PopupData2 = [
    {
        id: "01",
        image: "./images/portfolio/portfolio-01.png",
        popupLink: [
            './images/portfolio/portfolio-01-en.png',
        ],
    },
    {
        id: "02",
        image: "./images/portfolio/portfolio-02.png",
        popupLink: [
            './images/portfolio/portfolio-02-en.png',
        ],
    },
    {
        id: "03",
        image: "./images/portfolio/portfolio-03.png",
        popupLink: [
            './images/portfolio/portfolio-03-en.png',
        ],
    },
    {
        id: "04",
        image: "./images/portfolio/portfolio-04.png",
        popupLink: [
            './images/portfolio/portfolio-04-en.png',
        ],
    },
    {
        id: "05",
        image: "./images/portfolio/portfolio-05.png",
        popupLink: [
            './images/portfolio/portfolio-05-en.png',
        ],
    },
    {
        id: "06",
        image: "./images/portfolio/portfolio-06.png",
        popupLink: [
            './images/portfolio/portfolio-06-en.png',
        ],
    },
    {
        id: "07",
        image: "./images/portfolio/portfolio-07.png",
        popupLink: [
            './images/portfolio/portfolio-07-en.png',
        ],
    },
    {
        id: "08",
        image: "./images/portfolio/portfolio-08.png",
        popupLink: [
            './images/portfolio/portfolio-08-en.png',
        ],
    }
]
const PopupData3 = [
    {
        id: "01",
        image: "./images/portfolio/portfolio-01-.png",
        popupLink: [
            './images/portfolio/portfolio-01-es.png',
        ],
    },
    {
        id: "02",
        image: "./images/portfolio/portfolio-02-.png",
        popupLink: [
            './images/portfolio/portfolio-02-es.png',
        ],
    },
    {
        id: "03",
        image: "./images/portfolio/portfolio-03-.png",
        popupLink: [
            './images/portfolio/portfolio-03-es.png',
        ],
    },
    {
        id: "04",
        image: "./images/portfolio/portfolio-04-.png",
        popupLink: [
            './images/portfolio/portfolio-04-es.png',
        ],
    },
    {
        id: "05",
        image: "./images/portfolio/portfolio-05-.png",
        popupLink: [
            './images/portfolio/portfolio-05-es.png',
        ],
    },
    {
        id: "06",
        image: "./images/portfolio/portfolio-06-.png",
        popupLink: [
            './images/portfolio/portfolio-06-es.png',
        ],
    },
    {
        id: "07",
        image: "./images/portfolio/portfolio-07-.png",
        popupLink: [
            './images/portfolio/portfolio-07-es.png',
        ],
    },
    {
        id: "08",
        image: "./images/portfolio/portfolio-08-.png",
        popupLink: [
            './images/portfolio/portfolio-08-es.png',
        ],
    }
]

const Elements = () => {
    const {t, i18n} = useTranslation();
    return (
        <>


                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = {t("know")}
                                        title = {t("products-title")}
                                        description = {t("products-subtitle")}
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {i18n.language === "en" ? PopupData2.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                )) : PopupData3.map((item) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                                <div className="col-lg-10 offset-lg-1 mt-5">
                                    <div className="inner">
                                        <div className="content text-center">
                                            <div className="call-to-btn text-center">
                                                <Link className="btn-default btn-icon" to="/gallery">{t("know")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}



        </>
    )
}
export default Elements;