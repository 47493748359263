import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

import CircleProgress from '../elements/progressbar/CircleProgress';
import ServiceTwo from '../elements/service/ServiceTwo';
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
import GalleryTwo from '../elements/gallery/GalleryTwo';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import CounterUp from "../elements/counterup/CounterUp";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import {useTranslation} from "react-i18next";
var BlogListData = BlogClassicData.slice(0, 3);




const BusinessConsulting2 = () => {
    const {t, i18n} = useTranslation();
    return (
        <>
            <SEO title="Betconnections" />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title theme-gradient display-two">{t(("platform"))} <br /> {" "}
                                        <Typed
                                            strings={[
                                                "E-Gaming.",
                                                "SportsBetting.",
                                                "Live Casino.",
                                                "HorseRacing.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/gallery">{t("products")}<i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to={{ pathname: '/service', query: { id: 0 } }}>{t("services")}<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                                    <div className="carousel_wrapper">
                                    <div className="carousel">
                                        <div className="slide one">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-01.png"/> : 
                                                <img src="./images/portfolio/portfolio-01-.png"/>
                                            }
                                        </div>
                                        <div className="slide two">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-02.png"/> : 
                                                <img src="./images/portfolio/portfolio-02.png"/>
                                            }
                                        </div>
                                        <div className="slide three">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-03.png"/> : 
                                                <img src="./images/portfolio/portfolio-03.png"/>
                                            }
                                        </div>
                                        <div className="slide four">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-04.png"/> : 
                                                <img src="./images/portfolio/portfolio-04.png"/>
                                            }
                                        </div>
                                        <div className="slide five">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-05.png"/> : 
                                                <img src="./images/portfolio/portfolio-05-.png"/>
                                            }
                                        </div>
                                        <div className="slide six">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-06.png"/> : 
                                                <img src="./images/portfolio/portfolio-06.png"/>
                                            }
                                        </div>
                                        <div className="slide seven">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-07.png"/> : 
                                                <img src="./images/portfolio/portfolio-07.png"/>
                                            }
                                        </div>
                                        <div className="slide eight">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-08.png"/> : 
                                                <img src="./images/portfolio/portfolio-08-.png"/>
                                            }
                                        </div>
                                        <div className="slide nine">
                                            {i18n.language === "en" ? 
                                                <img src="./images/portfolio/portfolio-09.png"/> : 
                                                <img src="./images/portfolio/portfolio-09.png"/>
                                            }
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                

                {/* Start Call To Action Area  */}
                <div className="rwt-elements-area rn-service-area-default rn-section-gap">
                        <div className="container">
                        <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = {t("services")}
                                    title = {t("services-subtitle")}
                                    description = {t("services-description")}
                                    />
                            </div>
                            <div className="col-lg-12 mt--10">
                            <ServiceTwo id="service" cardStyle = "card-style-1" textAlign = "text-left" selection = ""/>   
                            </div>
                        </div>
                    </div>
                {/* End Call To Action Area  */}


                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoActionTwo />
                    </div>
                </div>
                {/* End Call To Action Area  */}

                {/* Start Call To Action Area  */}
                <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--25">
                                <div className="col-lg-10 offset-lg-1">
                                    <SectionTitle
                                        textAlign = "text-left"
                                        radiusRounded = ""
                                        subtitle = {t("Development")}
                                        title = "Whitelabels"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="col-lg-10 offset-lg-1 mt--10">
                                <CircleProgress />
                            </div>
                        </div>
                    </div>
                {/* End Call To Action Area  */}

                {/* Start Area  */}
                <Separator />
                {/* End  Area  */}                       


                {/* Start Call To Action Area  */}
                <GalleryTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" /> 
                {/* End Call To Action Area  */}

                <Separator /> 
                 {/* Start Elements Area  */}
                 <CounterUp column="col-lg-4 col-md-6 col-sm-6 col-12" counterStyle="counter-style-2 border-style" textALign="text-center" /> 
                    {/* End Elements Area  */}


                <Separator />                               
   
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "2022"
                                    title = {t("news")}
                                    description =  {t("news-subtitle")}
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default BusinessConsulting2;
