import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const callToActionData = {
    title: "Estamos listos para crear tu sitio de apuestas online",
    subtitle: "Casino en vivo, Ruletas, Maquinas Slots, Apuestas Deportivas y más",
}

const CalltoActionTwo = () => {
    const {t} = useTranslation();
    return (
        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-1.jpg)`}} data-black-overlay="8">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title mb--0">{t("we-are-ready")}</h2>
                                <p>{t("ready-description")}</p>
                                <div className="call-to-btn text-center">
                                    <Link className="btn-default btn-icon" to="/about-us">{t("contact")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionTwo;