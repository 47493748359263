import React from 'react';
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import {useTranslation} from "react-i18next";

const FooterTwo = () => {
    const {t, i18n} = useTranslation();
            const PopupData1 = [
        
                {
                    "id": 0,
                    "text": t("Aml Policy"),
                    "url": "./pdf/aml-policy_"
                },
                {
                    "id": 1,
                    "text": t("Self Exclusion"),
                    "url": "./pdf/self-exclusion_"
                },
                {
                    "id": 2,
                    "text": t("Amlkyc Policy"),
                    "url": "./pdf/amlkyc-policy_"
                },
                {
                    "id": 3,
                    "text": t("Juego Responsable"),
                    "url": "./pdf/juegos-responsable_"
                },
                {
                    "id": 4,
                    "text": t("Terms Services"),
                    "url": "./pdf/terms-services_"
                }
        
            ]
            const PopupData2 = [
                
                {
                    "id": 1,
                    "text": t("Dispute Resolution"),
                    "url": "./pdf/Dispute-resolution_"
                },
                {
                    "id": 2,
                    "text": t("Accounts Payouts"),
                    "url": "./pdf/accounts-payouts_"
                },
                {
                    "id": 3,
                    "text": t("Fairness RNG"),
                    "url": "./pdf/fairness-rng_"
                },
                {
                    "id": 4,
                    "text": t("Privacy Policy"),
                    "url": "./pdf/privacy-policy_"
                }
            ]

            const PopupData3 = [    

                {
                    "id": 1,
                    "text": t("about-us"),
                    "url": "/about-us"
                },
                {
                    "id": 2,
                    "text": t("Licence"),
                    "url": "/about-us"
                },
                {
                    "id": 4,
                    "text": t("news"),
                    "url": "/about-us"
                }
            ]
            const PopupData4 = [    
    
                {
                    "id": 1,
                    "text": t("Live-Slots"),
                    "url": "/gallery"
                },
                {
                    "id": 2,
                    "text": t("Terminales"),
                    "url": "/gallery"
                },
                {
                    "id": 3,
                    "text": t("Loteria-en-Vivo"),
                    "url": "/gallery"
                }
            ]
        
    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionSeven />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{t("Resources")}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {PopupData1.map((data, index) => (
                                                <li key={index}><a href={`${data.url}` + i18n.language + '.pdf'}>{data.text}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">{t("Resources")}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {PopupData2.map((data, index) => (
                                                    <li key={index}><a href={`${data.url}` + i18n.language + '.pdf'}>{data.text}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{t("Resources")}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {PopupData3.map((data, index) => (
                                                <li key={index}><a href={`${data.url}`}>{data.text}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{t("Resources")}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {PopupData4.map((data, index) => (
                                                <li key={index}><a href={`${data.url}`}>{data.text}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{t("stay-with-us")}</h4>
                                    <div className="inner">
                                        <h6 className="subtitle">{t("footer-subtitle")}</h6>
                                        <ul className="social-icon social-default justify-content-start">
                                        <li><a href="https://www.facebook.com/betconnectionscom" target='_blank' rel="noreferrer"><FiFacebook /></a></li>
                                        <li><a href="https://www.instagram.com/betconnections/" target='_blank' rel="noreferrer"><FiInstagram /></a></li>
                                        <li><a href="https://www.linkedin.com/company/betconnections" target='_blank' rel="noreferrer"><FiLinkedin /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
