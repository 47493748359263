import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {useTranslation} from "react-i18next";



const TimelineTwo = ({classVar}) => {
    const {t} = useTranslation();
    const TimelineData = [
        {
            id: "1",
            title: t("Knowledge"),
            description: t("Knowledge-content"),
        },
        {
            id: "2",
            title: t("Working"),
            description: t("Working-content"),
        },
        {
            id: "3",
            title: t("Solution"),
            description: t("Solution-content"),
        },
        {
            id: "4",
            title: t("Process"),
            description: t("Process-content"),
        },
    ]
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;