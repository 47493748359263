import React from 'react'
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import BrandThree from '../elements/brand/BrandThree'
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import {useTranslation} from "react-i18next";

const AboutUs = () => {
    const {t} = useTranslation();
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 slider-area-about-us height-850 bg_image">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="inner pt--80 text-left">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">{t("provider-title")}</span></h3>
                                    </div>
                                    <h1 className="title display-one">{t("about-subtitle")}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row pt-5">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">{t("about-title-left")}</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">{t("about-content")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}



                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}


                <Separator />

                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>


                <FooterTwo />
            </main>
        </>
    )
}

export default AboutUs;
