import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";

const Flipbook = () => {
    return (
        <>
            <Layout>
                <div className="rn-blog-details-area">
                    <iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=7nfgmnkrzp" width="100%" height="780" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay"></iframe>
                </div>
            </Layout>
        </>
    )
}
export default Flipbook;
