import { React, useState } from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import axios from 'axios';

const Nav = () => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [message, setMessage] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const handleShow = () => setShow(true);

    
    const {t, i18n} = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(name && email && phone && message) {
            axios.post("https://mail.betconnections.net/api/contact/send-mail",
                {
                    name: name,
                    email: email,
                    phone: phone,
                    message: message
                }
            ).then((response)=>{
                if (response.data) {
                    setIsLoggedIn(false)
                    resetForm()
                } else {
                    setIsLoggedIn(true)
                }
            })
        }
       
      }

      const handleClose = () => {
        setShow(false)
        resetForm()
        setIsLoggedIn(true)
      }
    
    const resetForm =() =>{
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')

      }
    const onNameChange =(event) =>{
        setName(event.target.value)
    }
  
    const onEmailChange =(event)=> {
        setEmail(event.target.value)
    }
  
    const onPhoneChange  =(event)=> {
        setPhone(event.target.value)
    }
  
    const onMessageChange =(event)=> {
        setMessage(event.target.value)
    }
    return (
        <ul className="mainmenu">
            
            <li><Link to="/">{t("home")}</Link></li>
            <li><Link to="/about-us">{t("about-us")}</Link></li>
            <li><Link to={{ pathname: '/service', query: { id: 0 } }}>{t("services")}</Link></li>
            <li><Link to="/gallery">{t("products")}</Link></li>
            <li onClick={() => handleShow()}><a>{t("contact")}</a></li>
            <li className="has-droupdown">
                <Link to="#">{
                    t("language")
                }</Link>
                <ul className="submenu">
                    <li>
                        <span onClick={
                                () => changeLanguage("es")
                        }>
                            {t("Español")}
                        </span>
                    </li>
                    <li >
                        <span onClick={
                                () => changeLanguage("en")
                        }>
                            {t("English")}
                        </span>
                    </li>
                </ul>
            </li>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
            <Modal.Header closeButton style={{ background: "#282828", height:'45px' }}>
                <Modal.Title>{t("contact")}</Modal.Title>
            </Modal.Header>
                <Modal.Body style={{maxHeight:'550px', overflow: 'auto'}}>
                {isLoggedIn ? <div className="App" style={{maxWidth:'390px', margin:'auto'}}>
                        <form id="contact-form" onSubmit={handleSubmit.bind(this)} method="POST">
                            <div className="form-group">
                                <label htmlFor="name">Nombres</label>
                                <input type="text" className="form-control" id="name" value={name}  onChange={onNameChange} style={{ height:'40px' }}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={email} onChange={onEmailChange.bind(this)} style={{ height:'40px' }}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Teléfono</label>
                                <input type="phone" className="form-control" id="phone" aria-describedby="phoneHelp" value={phone} onChange={onPhoneChange.bind(this)} style={{ height:'40px' }}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Mensaje</label>
                                <textarea className="form-control" rows="5" id="message" value={message} onChange={onMessageChange.bind(this)} style={{ minHeight:'150px' }}/>
                            </div>
                            <button type="submit" className="btn btn-primary btn-lg">ENVIAR</button>
                        </form>
                    </div> : 
                    <div style={{textAlign:'center'}}>Su mensaje ha sido enviado, estaremos contactandolo próximamente</div>
                        }
                </Modal.Body>
            </Modal>
        </ul>

    )
}
export default Nav;
