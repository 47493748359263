import {React, useState} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useTranslation} from "react-i18next";

const TabOne = () => {
    let state = useLocation();
    console.log(state.query.id,'id')
    const [tabIndex, setTabIndex] = useState(state.query.id);
    const {t} = useTranslation();
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                              
                                <div className="col-lg-12 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                        <TabList className="tab-button" >
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>{t("Diseño-Corporativo")}</button>
                                                    </div>   
                                                </Tab>
                                                <Tab >
                                                    <div className="rn-tab-button">
                                                        <button>{t("Marketing-Online")}</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>{t("Racebook-Online")}</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>{t("Asesoria-Legal")}</button>
                                                    </div>   
                                                </Tab>
                                        </TabList>
                                            <TabPanel>
                                                <div className="rn-tab-content row">
                                                    <div className="col-lg-5">
                                                        <img className="radius-small" src='/images/service/serviice-02.jpg' alt="Corporate React Template" />
                                                    </div>
                                                    <div className="col-lg-7 inner">
                                                        <h4>{t("Diseño-Corporativo")}</h4>
                                                        <p>{t("Diseño-content")}</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content row">
                                                    <div className="col-lg-5">
                                                        <img className="radius-small" src='/images/service/serviice-03.jpg' alt="Corporate React Template" />
                                                    </div>
                                                    <div className="col-lg-7 inner">
                                                        <h4>{t("Marketing-Online")}</h4>
                                                        <p>{t("Marketing-content")}</p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content row">
                                                    <div className="col-lg-5">
                                                        <img className="radius-small" src='/images/service/serviice-04.jpg' alt="Corporate React Template" />
                                                    </div>
                                                    <div className="col-lg-7 inner">
                                                        <h4>{t("Racebook-Online")}</h4>
                                                        <p>{t("Racebook-content")}</p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content row">
                                                    <div className="col-lg-5">
                                                        <img className="radius-small" src='/images/service/serviice-01.jpg' alt="Corporate React Template" />
                                                    </div>
                                                    <div className="col-lg-7 inner">
                                                        <h4>{t("Asesoria-Legal")}</h4>
                                                        <p>{t("Asesoria-content")}</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabOne
