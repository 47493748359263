import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";


i18next 
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources: {
        en: {
            translation: {
                "home": "Home",
                "back": "Back",
                "about-img": "./images/titles/about_us.png",
                "about-us": "Company",
                "about-title-left": "We work with the best suppliers in the world.",
                "services-img": "./images/titles/services_en.png",
                "read-img": "./images/services/btn-read-en.png",
                "services": "Services",
                "products-img": "./images/titles/products_en.png",
                "products": "Products",
                "news-img": "./images/titles/news_en.png",
                "news": "News",
                "catalogue": "Catalogue",
                "contact": "Contact",
                "language": "Languague",
                "banner-title-1": "LEADERS OF E-GAMING IN LATIN AMERICA",
                "banner-title-2": "ENJOY THE RACES",
                "banner-title-3": "THE LARGEST POKER NETWORK",
                "banner-title-4": "LIVE BETTING, PREMATCH SPORT",
                "banner-title-5": "THE WORLD OF BETS CHANGED",
                "banner-title-6": "LIVE CASINO",
                "services-subtitle": "What we can do for you: quality and excellent results!",
                "services-description": "We provide advice on everything related to the world of betting in Latin America and the world.",
                "products-title": "Our Products",
                "know": "Learn More",
                "products-subtitle": "Our portfolio of products and services includes: sports betting, casino, live casino, poker, streaming games, virtual sports, esports, international horse racing and more. Whatever your need, whether it is a complete turnkey platform or an integration of a sports betting APP; We have multiple options to offer you the best in the market.",
                "news-subtitle": "We constantly update the news related to the world of betting",
                "about-subtitle": "Specialists in B2B services for the gaming industry.",
                "about-content": "We are a global provider of iGaming software solutions offering its partners a world-class casino and sports betting platform, available as an omnichannel online, mobile and retail solution. Through the use and integration of superior technology solutions and an agile development methodology, we offer creative, cost-effective and customized solutions.",
                "provider-title": "Introducing Our Allies",
                "we-are-ready": "We are ready to create your online betting site",
                "ready-description": "We help our clients succeed by creating brand identities, digital experiences, and printed materials that communicate clearly.",
                "news-title": "Find out about the newest",
                "provider-btn": "See All",
                "all-rights": "All rights reserved",
                "curacao-title": "Curaçao License",
                "curacao-subtitle": "We have established commercial, technical and legal alliances with master license operators of the government of Curaçao, for this reason we can offer sub licenses derived from these master licenses, thus giving legal coverage to the operations of our clients in different territories where they wish to operate using these licenses. We thus manage the intermediation to carry out these processes and obtain these sub-licenses at very affordable costs.",
                "Live-Casino": "Live Casino",
                "Live-Slots": "Live Slots",
                "Live-Betting": "Live Betting",
                "Apuestas-Prematch": "SportsBook",
                "Carreras-de-Caballo": "CenterHorses",
                "Poker-en-Vivo": "Live Poker",
                "Terminales": "Machine Services",
                "Loteria-en-Vivo": "Live Lottery",
                "Video-Bingo": "Video Bingo",
                "Spectrum": "Spectrum",
                "Datos-Deportivos": "Sport Data",
                "Versus": "Versus",
                "XchanceBet": "XchanceBet",
                "Subastas-Hipicas": "Horse Auction",
                "Pagos": "Payment",
                "Peleas-de-Gallos": "Cockfights",
                "Diseño-Corporativo": "Corporate Design",
                "Diseño-content": "We create and develop a brand style, we design the branding and all the elements that represent your company for an internal and external communication strategy to give the greatest scope and obtain financial goals.",
                "Marketing-Online": "Marketing On line",
                "Marketing-content": "We specialize in the management and sale through social networks, Ads campaigns, creation of web pages and digital marketing strategy.",
                "Racebook-Online": "Racebook On line",
                "Racebook-content": "Horse racing betting software, brings together hundreds of tracks from the best racetracks in the world, all the tracks from USA, Canada, UK, Australia, Japan, Hong Kong and the most important classics from the Arab Emirates, Dubai and all the international gala horse racing calendar, the most complete coverage of Latin American horse racing, with races from Mexico, Panama, Ecuador, Dominican Republic, Chile, Uruguay and Argentina. The best and most complete Horseracing Betting System in the market.",
                "Asesoria-Legal": "Legal advice",
                "Asesoria-content": "We analyze the regulated trademarks in each jurisdiction of the country and offer the necessary advice for their legalization, we investigate the documentation of our clients in order to verify that they can commercialize in the regulated markets or provide alternatives in each case.",
                "read-more": "Read more",
                "Happy-Clients": "Happy Clients",
                "Employees": "Employees",
                "Conference": "Conference",
                "Total-Users": "Total Users",
                "platform": "The Best Platform",
                "Design": "Design",
                "Development": "Development",
                "Application": "Application",
                "Marketing": "Marketing",
                "Knowledge":"Knowledge",
                "Knowledge-content":"We have the experience you need",
                "Working":"Working",
                "Working-content":"The best professionals in the area",
                "Solution":"Solution",
                "Solution-content":"Agents authorized to assist you",
                "Process":"Process",
                "Process-content":"We make you succeed",
                "Español": "Spanish",
                "English": "English",
                "Licence": "Licence",
                "Solutions": "Solutions",
                "Resources": "Resources",
                "footer-subtitle": "Over 2,000 new customers sign up around the world.",
                "stay-with-us": "Stay with us.",
                "Copyright":"Copyright All rights reserved"
            }
        },
        es: {
            translation: {
                "home": "Inicio",
                "back": "Atrás",
                "about-img": "./images/titles/btn-about.png",
                "about-us": "Nosotros",
                "about-title-left": "Trabajamos con los mejores proveedores del mundo.",
                "services-img": "./images/titles/btn-services.png",
                "read-img": "./images/services/btn-read.png",
                "services": "Servicios",
                "products-img": "./images/titles/btn-products.png",
                "products": "Productos",
                "news-img": "./images/titles/btn-news.png",
                "news": "Noticias",
                "catalogue": "Catálogo",
                "contact": "Contacto",
                "language": "idioma",
                "banner-title-1": "LÍDERES DEL E-GAMING EN LATINOAMERICA",
                "banner-title-2": "DISFRUTE DE LAS CARRERAS",
                "banner-title-3": "LA RED MÁS GRANDE DE POKER",
                "banner-title-4": "APUESTAS EN VIVO, APUESTAS PREMATCH",
                "banner-title-5": "EL MUNDO DE LAS APUESTAS CAMBIO",
                "banner-title-6": "CASINO EN VIVO",
                "services-subtitle": "Lo que podemos hacer por usted: ¡calidad y excelentes resultados!",
                "services-description": "Brindamos asesoría de todo lo relacionado al mundo de las apuestas en América latina y el mundo.",
                "know": "Conoce Más",
                "products-title": "Nuestros Productos",
                "products-subtitle": "Nuestra cartera de productos y servicios incluye: apuestas deportivas, casino, casino en vivo, póquer, juegos en streaming, deportes virtuales, deportes electrónicos,  carreras de caballos internacionales y más. Independientemente cual sea su necesidad, bien sea una plataforma completa, llave en mano o una integración de una APP de apuestas deportivas; tenemos múltiples  opciones para ofrecerles lo mejor del mercado.",
                "news-subtitle": "Actualizamos de forma constante las noticias relacionadas al mundo de las apuestas",
                "about-subtitle": "Especialistas en servicios B2B para la industria del gaming.",
                "about-content": "Somos proveedor global de soluciones de software de iGaming que ofrece a sus socios una plataforma de apuestas deportivas y casinos de clase mundial, está disponible como una solución omnicanal en línea, móvil y minorista. Mediante el uso y la integración de soluciones tecnológicas superiores y una metodología de desarrollo ágil, ofrecemos soluciones creativas, rentables y personalizadas.",
                "provider-title": "Presentamos a Nuestros Aliados",
                "we-are-ready": "Estamos listos para crear tu sitio de apuestas online",
                "ready-description": "Ayudamos a nuestros clientes a tener éxito mediante la creación de identidades de marca, experiencias digitales y materiales impresos que se comunican con claridad.",
                "news-title": "Enterate de los más nuevo",
                "provider-btn": "Ver todos",
                "all-rights": "Todos los derechos reservados",
                "curacao-title": "Licencia Curazao",
                "curacao-subtitle": "Hemos establecido alianzas comerciales, técnicas y jurídicas con operadores master de licencia del gobierno de curazao, por ello podemos ofrecer sub licencias derivadas de estas licencias master, dando así cobertura legal a las operaciones de nuestros clientes en diferentes territorios donde deseen operar utilizando estas licencias, gestionamos así la intermediación para llevar estos procesos y obtener estas sub licencias a costos muy accesibles.",
                "Live-Casino": "Casino en Vivo",
                "Live-Slots": "Slots",
                "Live-Betting": "Apuestas en Vivo",
                "Apuestas-Prematch": "Apuestas Prematch",
                "Carreras-de-Caballo": "Caballos",
                "Poker-en-Vivo": "Poker en Vivo",
                "Terminales": "Terminales",
                "Loteria-en-Vivo": "Loteria en Vivo",
                "Video-Bingo": "Video Bingo",
                "Spectrum": "Spectrum",
                "Datos-Deportivos": "Datos Deportivos",
                "Versus": "Versus",
                "XchanceBet": "XchanceBet",
                "Subastas-Hipicas": "Subastas Hípicas",
                "Pagos": "Pagos",
                "Peleas-de-Gallos": "Peleas de Gallos",
                "Diseño-Corporativo": "Diseño Corporativo",
                "Diseño-content": "Creamos y desarrollamos un estilo de marca, diseñamos el branding  y todos los elementos que representan su empresa para una estrategía de comunicación interna y externa para dar el mayor alcance y obtención de las metas financieras.",
                "Marketing-Online": "Marketing On line",
                "Marketing-content": "Nos especializamos en la gestión y venta por medio de redes sociales, campañas Ads, creación de páginas web y estrategia de mercadeo digitales.",
                "Racebook-Online": "Racebook On line",
                "Racebook-content": "Software de apuestas hípicas, reúne cientos de pistas de los mejores circuitos de carreras del mundo, todas las pistas de USA, Canadá, Reino Unido, Australia, Japón, Hong Kong y los más importantes clásicos de los Emiratos Árabes, Dubái y todo el calendario hípico de gala internacional, cobertura más completa del hipismo latinoamericano, con carreras desde México, Panamá, Ecuador, Rep. Dominicana, Chile, Uruguay y Argentina. El mejor y más completo Sistema de Apuestas Hípicas del Mercado.",
                "Asesoria-Legal": "Asesoria Legal",
                "Asesoria-content": "Analizamos las marcas reguladas en cada jurisdicción del país y ofrecemos la asesoría necesaria para su legalización, investigamos sobre la documentación de nuestros clientes a fin de verificar que puedan comercializar en los mercados regulados o dar las alternativas en cada caso.",
                "read-more": "Leer más",
                "Happy-Clients": "Clientes Felices",
                "Employees": "Empleados",
                "Conference": "Conferencias",
                "Total-Users": "Total Usuarios",
                "platform": "La Mejor Plataforma",
                "Design": "Diseño",
                "Development": "Desarrollo",
                "Application": "Aplicación",
                "Marketing": "Marketing",
                "Knowledge":"Conocimiento",
                "Knowledge-content":"Tenemos la experiencia que necesita",
                "Working":"Trabajo",
                "Working-content":"Los mejores profesionales del área",
                "Solution":"Soluciones",
                "Solution-content":"Agentes autorizados para atenderlos",
                "Process":"Proceso",
                "Process-content":"Hacemos que llegues al éxito",
                "Español": "Español",
                "English": "Ingles",
                "Licence": "Licencia",
                "Solutions": "Soluciones",
                "Resources": "Recursos",
                "footer-subtitle": "Más de 2000 de nuevos clientes se suscriben alrededor del mundo.",
                "stay-with-us": "Quedate con Nosotros.",
                "Copyright":"Copyright Todos los Derechos Reservados",
                "Aml Policy": "Política AML",
                "Self Exclusion": "AutoExclusión",
                "Amlkyc Policy": "Política de KYC",
                "Juego Responsable": "Juego Responsable",
                "Terms Services" : "Términos de Servicio",
                "Dispute Resolution": "Resolución de Disputas",
                "Accounts Payouts": "Cuentas, Pagos y Bonos",
                "Fairness RNG": "Métodos de prueba de Justicia y RNG",
                "Privacy Policy": "Privacidad y gestión de datos personales",
            }
        },
    },
    lng: "es",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    }
})